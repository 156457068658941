import { Observable, switchMap, take } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { GlobalState } from '../store/global/global.state';
import { selectCurrentOrganizationId } from '../store/global/global.selectors';
import { Injectable } from '@angular/core';

@Injectable()
export class OrganizationInterceptor implements HttpInterceptor {
    // TODO: seems like this class is overshadowed by apollo-link.provider.ts
    // this is due to interceptors not working with GraphQL batches
    // consider future of this class
    constructor(private store: Store<GlobalState>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store.select(selectCurrentOrganizationId).pipe(
            take(1),
            switchMap((organizationId) => {
                if (!organizationId) {
                    return next.handle(req);
                }

                const modifiedRequest = req.clone({
                    headers: req.headers.set('selectedOrganizationId', organizationId?.toString() || ''),
                });

                return next.handle(modifiedRequest);
            }),
        );
    }
}
