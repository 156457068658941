import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { whoami } from './store/global/global.actions';
import { selectCurrentUserId } from './store/global/global.selectors';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { combineLatest, startWith } from 'rxjs';
import { pageWithoutLayout } from './app.utils';
import { SvgIconRegistryService } from 'angular-svg-icon';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    backendUrl = `${environment.apiUrl}/crm`;
    redirectUrl = environment.bankRedirectUrl;

    protected displayLayout$ = combineLatest([
        this.store.select(selectCurrentUserId).pipe(startWith(false)),
        this.router.events,
    ]).pipe(
        map(([userId, event]) => {
            if (!userId) return false;
            return !pageWithoutLayout(this.router.url);
        }),
    );

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly svgRegistry: SvgIconRegistryService,
    ) {}

    public ngOnInit(): void {
        // return url from Microsoft / Gmail logins
        if (!window.location.href.includes('/logon')) {
            this.store.dispatch(whoami());
        }

        // load svg icons into svg registry service
        combineLatest([
            this.svgRegistry.loadSvg('/assets/svg/envelope.svg', 'envelope'),
            this.svgRegistry.loadSvg('/assets/svg/envelope-open.svg', 'envelope-open'),
            this.svgRegistry.loadSvg('/assets/svg/gear.svg', 'gear'),
            this.svgRegistry.loadSvg('/assets/svg/trash3.svg', 'trash3'),
            this.svgRegistry.loadSvg('/assets/svg/bookmark.svg', 'bookmark'),
            this.svgRegistry.loadSvg('/assets/svg/bookmark-x.svg', 'bookmark-x'),
            this.svgRegistry.loadSvg('/assets/svg/arrow-return-left.svg', 'arrow-return-left'),
            this.svgRegistry.loadSvg('/assets/svg/asterisk.svg', 'asterisk'),
            this.svgRegistry.loadSvg('/assets/svg/house-door-fill.svg', 'house-door-fill'),
            this.svgRegistry.loadSvg('/assets/svg/person-circle.svg', 'person-circle'),

            this.svgRegistry.loadSvg('/assets/images/XC_logo-horizont.svg', 'loader'),
            this.svgRegistry.loadSvg('/assets/images/XC_logo-horizont-inv.svg', 'loader-inv'),

        ]).subscribe();
    }
}
