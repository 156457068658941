import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationsService } from '../../services/notifications.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { catchError, map, of, switchMap } from 'rxjs';
import * as actions from './notifications.actions';
import { deleteNotificationSuccess } from './notifications.actions';
import { EffectFactory } from '../../../shared/store/common/effect.factory';

@Injectable()
export class NotificationEffects {
    constructor(
        private actions$: Actions,
        private api: NotificationsService,
        private store: Store,
        private router: Router,
    ) {
    }

    factory = new EffectFactory(this.actions$, actions, this.api).create();

    getNotificationTypes$ = this.factory.getNotificationTypes;
    getNotifications$ = this.factory.getNotifications;

    deleteNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteNotification),
            switchMap(({ input }) =>
                this.api.deleteNotification(input.id).pipe(
                    map(() => deleteNotificationSuccess({ notification: { ok: true }})),
                    catchError(() =>
                        of(actions.deleteNotificationFailure({ notification: null })),
                    ),
                ),
            ),
        ),
    );

    readNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.readNotification),
            switchMap(({ input }) =>
                this.api.updateNotification(input).pipe(
                    map((read) =>
                        actions.readNotificationSuccess({ read }),
                    ),
                    catchError(() =>
                        of(actions.readNotificationFailure({ read: null })),
                    ),
                ),
            ),
        ),
    );

    receiveNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.receiveNotification),
            switchMap(({ user }) =>
                this.api
                    .getSubscription(user)
                    .pipe(
                        map((notification) =>
                            actions.receiveNotificationSuccess({ notification }),
                        ),
                    ),
            ),
        ),
    );

}
