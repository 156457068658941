import { Component } from '@angular/core';
import { ForgottenPasswordPageStore } from './forgotten-password-page.store';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-forgotten-password',
    templateUrl: 'forgotten-password-page.component.html',
    styleUrl: 'forgotten-password-page.component.scss',
    providers: [ForgottenPasswordPageStore],
})
export class ForgottenPasswordPageComponent extends SubscriptionComponent {

    protected username = '';
    protected sent = false;
    protected sentSuccess = new Subject<boolean>();

    constructor(
        protected readonly store: ForgottenPasswordPageStore,
        private readonly router: Router,
    ) {
        super();
        this.subs.push(
            this.store.apiSuccess.forgottenPassword.subscribe(() => {
                this.sentSuccess.next(true);
            }),
        );
    }

    protected sendReset(): void {
        if (!this.username) return;
        this.sent = true;
        this.store.api.forgottenPassword({ email: this.username });
    }

    protected allowResent(): void {
        this.sent = false;
        this.sentSuccess.next(false);
    }

    protected async goToLogin(): Promise<void> {
        await this.router.navigateByUrl('login');
    }

}
