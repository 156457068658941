import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, tap } from 'rxjs/operators';
import { of, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from '../../shared/models/language.enum';
import * as actions from './layout.actions';
import { SessionService } from '../../../services/session.service';
import { ApiService } from '../services/api.service';
import { translateCalendar } from '../services/calendar-translation.service';
import { PrimeNGConfig } from 'primeng/api';

@Injectable()
export class LayoutEffects {
  changeLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.changeLanguage),
        tap(({ language }) => {
          const lang = language === 'cs' ? LanguageEnum.CZECH : language;
          this.translate.use(lang);
          this.session.registerLanguage(lang);
          translateCalendar(this.translate, this.primengConfig);
        }),
      ),
    { dispatch: false },
  );

  changeUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeUserSettings),
      switchMap(({ input }) =>
        this.api.updateUserSettings(input).pipe(
          map(() => actions.changeUserSettingsSuccess()),
          catchError((error) =>
            of(actions.changeUserSettingsFailure({ error })),
          ),
        ),
      ),
    ),
  );

  getUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUserSettings),
      switchMap(() =>
        this.api.getUserSettings().pipe(
          map((settings) =>
            actions.getUserSettingsSuccess({ userSettings: settings }),
          ),
          catchError((error) => of(actions.getUserSettingsFailure({ error }))),
        ),
      ),
    ),
  );

  changeUserSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeUserSettingsSuccess),
      map(() => actions.getUserSettings()),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private translate: TranslateService,
    private session: SessionService,
    private primengConfig: PrimeNGConfig,
  ) {}
}
