import { SubscriptionComponent } from '../../../../shared/helpers/subscription.component';
import { LoginCodePageStore } from './login-code-page.store';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-login-code-page',
    templateUrl: 'login-code-page.component.html',
    styleUrl: 'login-code-page.component.scss',
    providers: [LoginCodePageStore],
})
export class LoginCodePageComponent extends SubscriptionComponent {

    protected code = '';
    protected err$ = new Subject();

    constructor(
        protected readonly store: LoginCodePageStore,
        private readonly router: Router,
    ) {
        super();
        if (!this.store.userId) {
            this.router.navigateByUrl('/login');
        }
        this.subs.push(
            this.store.data.loginCode$.subscribe((it) => {
                if (it) {
                    this.router.navigateByUrl('/');
                }
            }),
            this.store.apiFailure.loginCode.subscribe(() => {
                this.err$.next(true);
            }),
        );
    }

    protected login(): void {
        if (this.code.length === 6) {
            this.err$.next(false);
            this.store.api.loginCode({
                userId: this.store.userId,
                code: this.code,
            });
        }
    }

}
