import { Component, OnInit } from '@angular/core';

declare let particlesJS: any;

@Component({
    selector: 'app-login-layout',
    templateUrl: 'login-layout.component.html',
    styleUrl: 'login-layout.component.scss',
})
export class LoginLayoutComponent implements OnInit {

    constructor() {
        // Tohle je duplicita, protže aktuálně jsou styly zahrabané až někde v layout, takže na pičo, bo to tu není inicializované
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        document.head.appendChild(style);

        const theme = localStorage.getItem('theme') ?? 'light-blue';
        const scheme = theme.split('-')[0];

        if (localStorage.getItem('theme') !== undefined) {
            style.href = `/${scheme}.css`;
        }
    }

    public ngOnInit(): void {
        particlesJS.load('particles-js', 'assets/styles/particles.json', null);
    }

}
