import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OkObject, UpdateUserSettingsInput, UserSettingsObject } from '@it2go/types';
import { GqlService } from '../../content/service/gql.service';

@Injectable()
export class ApiService extends GqlService {

    updateUserSettings(input: UpdateUserSettingsInput): Observable<OkObject> {
        return this.mutate<OkObject>(`
            mutation ($input: UpdateUserSettingsInput!) {
                user {
                    updateUserSettings(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    getUserSettings(): Observable<UserSettingsObject> {
        return this.query<UserSettingsObject>(`
            query {
                user {
                    getUserSettings {
                        language
                        colorScheme
                        floatLabelScheme
                        menuType
                        menuTheme
                        componentTheme
                        scale
                        inputStyle
                        ripple
                    }
                }
            }
        `);
    }
}
