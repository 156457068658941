import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { whoami, whoamiSuccess } from '../../../../store/global/global.actions';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { LoginPageStore } from './login-page.store';
import { Subject } from 'rxjs';
import { AuthenticatedUserObject } from '@it2go/types';

@Component({
    selector: 'app-login-page',
    templateUrl: 'login-page.component.html',
    styleUrls: ['login-page.component.scss'],
    providers: [LoginPageStore],
})
export class LoginPageComponent extends SubscriptionComponent implements OnInit {

    username = '';
    password = '';
    maskPassword = true;
    logging = new Subject<boolean>();
    invalid = new Subject<boolean>();
    expired = new Subject<boolean>();

    constructor(
        protected readonly store: LoginPageStore,
        private readonly router: Router,
    ) {
        super();
    }

    public ngOnInit(): void {
        const { email } = window.history.state;
        if (email) {
            this.username = email;
        }

        this.subs.push(
            this.store.apiSuccess.login.subscribe((it: AuthenticatedUserObject|null) => {
                if (it?.auth2fa) {
                    this.router.navigateByUrl('/login-code');
                    this.store.globalStore!.dispatch(whoamiSuccess({ whoami: it }));
                } else if (it?.auth2fa === false) {
                    this.store.globalStore!.dispatch(whoami());
                    this.router.navigateByUrl('/');
                }
            }),
            this.store.apiFailure.login.subscribe((it: string) => {
                this.logging.next(false);
                if (it.includes('Expired')) {
                    this.expired.next(true);
                } else {
                    this.invalid.next(true);
                }
            }),
        );
    }

    login(): void {
        this.logging.next(true);
        this.expired.next(false);
        this.invalid.next(false);
        this.store.api.login({
            username: this.username,
            password: this.password,
        });
    }

    togglePasswordMask() {
        this.maskPassword = !this.maskPassword;
    }

    @HostListener('window:keyup', ['$event'])
    loginEvent(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            this.login();
        }
    }

    protected msLogin(): void {
        const msTenant = environment.msClientId;
        const logon = environment.msLogonUrl;
        window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${msTenant}&response_type=id_token&redirect_uri=${encodeURI(logon)}&response_mode=fragment&scope=openid+profile+email&nonce=666`;
    }

    protected async goToForgotten(): Promise<void> {
        await this.router.navigateByUrl('forgotten-password');
    }

}

