import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { StyleClassModule } from 'primeng/styleclass';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BreadcrumbComponent } from './components/breadcrumbs/breadcrumb.component';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { RightMenuComponent } from './components/right-menu/right-menu.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SearchComponent } from './components/search/search.component';
import { FooterComponent } from './components/footer/footer.component';
import { LocalDatePipe } from '../shared/pipes/local-date.pipe';
import { FormatSizePipe } from '../shared/pipes/format-size.pipe';
import { CommonModule } from '@angular/common';
import { ConfigComponent } from './components/config/config.component';
import { LayoutEffects } from './store/layout.effects';
import { layoutStore } from './store/layout.reducer';
import { ApiService } from './services/api.service';
import { ApolloModule } from 'apollo-angular';
import { apolloLinkProvider } from '../shared/apollo-links/apollo-link.provider';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { BreadcrumbFirstPartPipe } from '../shared/pipes/breadcrumb-first-part.pipe';
import { BreadcrumbSecondPartPipe } from '../shared/pipes/breadcrumb-second-part.pipe';
import { BreadcrumbDoesContainSecondPartPipe } from '../shared/pipes/breadcrumb-does-contain-second-part.pipe';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { notificationsFeatureKey } from './store/notifications/notifications.state';
import { notificationsReducer } from './store/notifications/notifications.reducer';
import { NotificationEffects } from './store/notifications/notifications.effects';
import { NotificationsService } from './services/notifications.service';
import { OrganizationSelectComponent } from './components/organization-select/organization-select.component';
import { SharedModule } from '../shared/shared.module';
import { LayoutServicesService } from './services/service.service';
import { SearchService } from './services/search.service';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SearchResultComponent } from './components/search/search-result/search-result.component';
import { TagModule } from 'primeng/tag';
import { InplaceModule } from 'primeng/inplace';

@NgModule({
    declarations: [
        LayoutComponent,
        BreadcrumbComponent,
        SidebarComponent,
        TopbarComponent,
        RightMenuComponent,
        MenuComponent,
        MenuItemComponent,
        SearchComponent,
        FooterComponent,
        OrganizationSelectComponent,
        SearchResultComponent,
    ],
    imports: [
        LocalDatePipe,
        FormatSizePipe,
        CommonModule,
        ConfigComponent,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        ButtonModule,
        TooltipModule,
        RippleModule,
        MenuModule,
        RouterModule,
        DropdownModule,
        DividerModule,
        DialogModule,
        StyleClassModule,
        TranslateModule.forChild({ extend: true }),
        EffectsModule.forFeature([
            LayoutEffects,
            NotificationEffects,
        ]),
        StoreModule.forFeature(notificationsFeatureKey, notificationsReducer),
        StoreModule.forFeature(layoutStore),
        ApolloModule,
        BreadcrumbModule,
        BreadcrumbFirstPartPipe,
        BreadcrumbSecondPartPipe,
        NotificationsComponent,
        BreadcrumbDoesContainSecondPartPipe,
        SharedModule,
        TabViewModule,
        CardModule,
        ProgressSpinnerModule,
        TagModule,
        InplaceModule,
    ],
    exports: [LayoutComponent],
    providers: [
        ApiService,
        apolloLinkProvider,
        NotificationsService,
        LayoutServicesService,
        SearchService,
    ],
})
export class LayoutModule {
}
