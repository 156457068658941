import { ComponentStoreFactory } from '../../../shared/store/common/component-store.factory';
import { Store } from '@ngrx/store';
import { AuthorizationService } from '../../../../services/authorization.service';
import { Injectable } from '@angular/core';

interface State {
}

const defaultState: State = {};

@Injectable()
export class ResetPasswordPageStore extends ComponentStoreFactory<State> {

    constructor(
        store: Store,
        svc: AuthorizationService,
    ) {
        super(defaultState, store, svc);
    }

}
