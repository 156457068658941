import { ActivatedRoute, Router } from '@angular/router';

// refreshes current page if it is needed
export async function handleRouteRefreshOn(key: string, router: Router, content_route: ActivatedRoute) {
    let route: ActivatedRoute | null = content_route;
    while (route) {
        const data = route.snapshot.data;
        const reloadOn = data['reloadOn'] as string[] | undefined;
        if (reloadOn) {
            if (reloadOn.includes(key)) {
                const url = router.url;
                await router
                    .navigate(['/'], { skipLocationChange: true }) // dummy location to retrigger ngOnInit
                    .then(() => router.navigate([url]));
                break;
            }
        }

        route = route.firstChild;
    }
}
