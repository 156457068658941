import { Injectable } from '@angular/core';
import { ComponentStoreFactory } from '../../../../shared/store/common/component-store.factory';
import { AuthorizationService } from '../../../../../services/authorization.service';
import { Store } from '@ngrx/store';
import { AuthenticatedUserObject } from '@it2go/types';

interface State {
    loginCode: AuthenticatedUserObject|null
}

const defaultState: State = {
    loginCode: null,
};

@Injectable()
export class LoginCodePageStore extends ComponentStoreFactory<State> {

    constructor(
        svc: AuthorizationService,
        store: Store,
    ) {
        super(defaultState, store, svc);
    }

}
