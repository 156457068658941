import { Component } from '@angular/core';
import { ResetPasswordPageStore } from './reset-password-page.store';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password-page.component.html',
    styleUrl: 'reset-password-page.component.scss',
    providers: [ResetPasswordPageStore],
})
export class ResetPasswordPageComponent extends SubscriptionComponent {

    protected password = '';
    protected sent = false;
    protected maskPassword = true;

    constructor(
        protected readonly store: ResetPasswordPageStore,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) {
        super();
        this.subs.push(
            this.store.apiSuccess.resetPassword.subscribe(async () => {
                const token = await this.getToken();
                const { email } = JSON.parse(atob(token?.split('.')?.[1] || ''));

                return this.router.navigateByUrl('login', { state: { email } });
            }),
        );
    }

    protected togglePasswordMask(): void {
        this.maskPassword = !this.maskPassword;
    }

    protected async setPassword(): Promise<void> {
        const token = await this.getToken();
        if (!token) return;

        this.store.api.resetPassword({ password: this.password, token });
    }

    private async getToken(): Promise<string | null> {
        const params = await this.store.get(this.route.queryParamMap);
        return params.get('token');
    }

}
