import { createFeature, createReducer, on } from '@ngrx/store';
import { createInitialLayoutState } from './layout.state';
import * as actions from './layout.actions';
import { setCurrentOrganizationId } from '../../../store/global/global.actions';

export const layoutStore = createFeature({
    name: 'layout',
    reducer: createReducer(
        createInitialLayoutState(),

        on(actions.changeGroupId, (state, { groupId }) => ({
            ...state,
            groupId,
        })),

        on(actions.getUserSettingsSuccess, (state, { userSettings }) => ({
            ...state,
            userSettings,
        })),

        on(actions.setBackendUrl, (state, { backendUrl }) => ({
            ...state,
            backendUrl,
        })),
        on(setCurrentOrganizationId, (state, { organizationId }) => ({
            ...state,
            groupId: organizationId.toString(),
        })),
    ),
});

export const { name, reducer, selectLayoutState } = layoutStore;
