import { Injectable } from '@angular/core';
import { ComponentStoreFactory } from '../../../shared/store/common/component-store.factory';
import { AuthorizationService } from '../../../../services/authorization.service';
import { Store } from '@ngrx/store';

interface State {
}

const defaultState: State = {};

@Injectable()
export class ForgottenPasswordPageStore extends ComponentStoreFactory<State> {

    constructor(
        svc: AuthorizationService,
        store: Store,
    ) {
        super(defaultState, store, svc);
    }

}
