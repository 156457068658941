import { Store } from '@ngrx/store';
import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalState } from '../store/global/global.state';
import { setLanguage } from '../store/global/global.actions';
import { LanguageEnum } from '../modules/shared/models/language.enum';

const initializeLanguage =
  (translate: TranslateService, store: Store<GlobalState>) => () => {
    const language = translate.getBrowserLang() || translate.defaultLang;
    store.dispatch(
      setLanguage(
        language === 'cs' ? LanguageEnum.CZECH : (language as LanguageEnum),
      ),
    );
  };

export const languageInitializer = {
  multi: true,
  provide: APP_INITIALIZER,
  useFactory: initializeLanguage,
  deps: [TranslateService, Store],
};
