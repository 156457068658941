export const notificationFrag = `
    authorName
    createdAt
    groupId
    id
    isRead
    link
    pictureUrl
    subtitle
    title
    type
    userId
`;

export const notificationGridItemFrag = `
    authorName
    createdAt
    groupId
    id
    isRead
    link
    pictureUrl
    subtitle
    title
    type
    userId
`;
