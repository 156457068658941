import { NgModule } from '@angular/core';
import { routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { globalFeatureKey } from './store/global/global.state';
import { globalReducer } from './store/global/global.reducer';
import { GlobalEffects } from './store/global/global.effects';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    StoreModule.forRoot({
      [globalFeatureKey]: globalReducer,
      router: routerReducer,
    }),
    EffectsModule.forRoot([GlobalEffects]),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
  ],
  exports: [StoreModule, EffectsModule],
})
export class AppStoreModule {}
