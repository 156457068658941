<app-login-layout *ngIf="{ logging: logging | async, invalid: invalid | async, expired: expired | async } as data">
    <h2 class="text-white">{{ 'login.title' | translate }}</h2>
    <p-divider></p-divider>
    <span class="p-input-icon-right">
        <i class="pi pi-user" style="color: orange;"></i>
        <input
            name="email"
            id="email"
            [(ngModel)]="username"
            pInputText
            class="inputType text-white"
            [placeholder]="'login.email' | translate"
        />
    </span>

    <span class="p-input-icon-right">
        <i [ngClass]="{pi: true, 'pi-eye': maskPassword, 'pi-eye-slash': !maskPassword}" style="color: orange;"
           (click)="togglePasswordMask()"></i>
        <input
            name="password"
            [type]="maskPassword ? 'password' : 'text'"
            id="password"
            [(ngModel)]="password"
            pInputText
            class="inputType text-white"
            [placeholder]="'login.password' | translate"
        />
    </span>

    <div class="text-danger" *ngIf="data.invalid">{{ 'common.login.invalid' | translate }}</div>
    <div class="text-danger" *ngIf="data.expired">{{ 'common.login.expired' | translate }}</div>

    <button
        pButton
        pRipple
        type="button"
        label="{{ (data.logging ? 'login.buttonLoading' : 'login.button') | translate }}"
        style="min-width: 10rem;"
        [loading]="data.logging"
        class="buttonType mt-3"
        (click)="login()"
    ></button>

    <p-button (onClick)="msLogin()">
        <img src="../../../../../assets/images/ms-symbollockup_signin_light.svg" alt="SignInMicrosoft"/>
    </p-button>

    <div style="color: #FFF !important;">
        <p-button [label]="'forgottenPassword' | translate" [link]="true" (onClick)="goToForgotten()"></p-button>
    </div>
</app-login-layout>
