import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';
import { GridInput } from '@it2go/data-grid';
import { ServiceGridObject } from '@it2go/types';
import { filterFrag } from '../../content/service/types/filter.types';
import { serviceGridItemFrag } from '../../content/service/types/service.types';

@Injectable()
export class LayoutServicesService {
    constructor(private http: HttpClient, private apollo: Apollo) {
    }

    getServices(input: GridInput | null = null): Observable<ServiceGridObject> {
        return this.apollo.query<{ service: { filter: ServiceGridObject } }>({
            query: gql`
                query($input: GridInput) {
                    service {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceGridItemFrag}
                            }
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: { input },
        }).pipe(map((result) => result.data.service.filter));
    }

}
