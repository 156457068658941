import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import * as actions from './auth.actions';
import { ApiService } from '../services/api.service';

@Injectable()
export class AuthEffects {
  loadAuths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginUser),
      concatMap(({ username, password, language }) =>
        this.api.login(username, password).pipe(
          map((data) => actions.loginUserSuccess({ data, language })),
          catchError((error) => of(actions.loginUserFailure({ error }))),
        ),
      ),
    ),
  );

  redirectAfterLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.loadUserDataSuccess),
        switchMap(() => this.router.routerState.root.children[0].url),
        map((url) => url[0].path),
        tap((path) => {
          if (path === 'auth') {
            this.router.navigate(['']);
          }
        }),
      );
    },
    { dispatch: false },
  );

  loadUserDataAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginUserSuccess),
      map(() => actions.loadUserData()),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private router: Router,
  ) {}
}
