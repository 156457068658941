import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GqlService } from '../modules/content/service/gql.service';
import { EventObject } from '@libs/types/src/notifications/object-type/event.object';

@Injectable()
export class NotificationService extends GqlService {

    constructor(
        apollo: Apollo,
    ) {
        super(apollo);
    }

    eventSubscription(): Observable<EventObject> {
        return this.apollo.subscribe<{ events: EventObject }>({
            query: gql`
                subscription {
                    events {
                        name
                    }
                }
            `,
        })
            .pipe(map((res) => res.data!.events));
    }

}
