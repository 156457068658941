import { ComponentStoreFactory } from '../../../shared/store/common/component-store.factory';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { FileService } from '../../../shared/service/file.service';

interface State {
}

const defaultState: State = {};

@Injectable()
export class ShareFilePageStore extends ComponentStoreFactory<State, FileService> {

    constructor(
        store: Store,
        svc: FileService,
    ) {
        super(defaultState, store, svc);
    }

}
