import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';
import { SearchInput } from '@libs/types/src/common/input-type/search.input';
import { map } from 'rxjs';
import { SearchObject } from '@libs/types/src/common/object-type/search.output';

@Injectable()
export class SearchService {

    constructor(private apollo: Apollo) {
    }

    search(input: SearchInput) {
        return this.apollo.query<{ search: SearchObject }>({
            query: gql`
                query($input: SearchInput!) {
                    search(input: $input) {
                        page
                        limit
                        items {
                            id
                            entityType
                            data
                        }
                    }
                }
            `,
            variables: { input },
        }).pipe(map((result) => result.data.search));
    }

}
