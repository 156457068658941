<app-login-layout>
    <h2 class="text-white">{{ 'forgottenPassword' | translate }}</h2>
    <p-divider></p-divider>
    <span class="p-input-icon-right">
        <i class="pi pi-user" style="color: orange;"></i>
        <input
            name="email"
            id="email"
            [(ngModel)]="username"
            pInputText
            class="inputType text-white"
            [placeholder]="'login.email' | translate"
            (ngModelChange)="allowResent()"
        />
    </span>
    <div style="color: #FFF !important" *ngIf="sentSuccess | async">{{ 'common.login.forgottenSend' | translate }}</div>

    <button
        pButton
        pRipple
        type="button"
        label="{{ ( sent ? 'common.sent' : 'common.send') | translate }}"
        style="min-width: 10rem;"
        [disabled]="sent"
        class="buttonType mt-3"
        (click)="sendReset()"
    ></button>

    <div style="color: #FFF !important;">
        <p-button [label]="'common.goBack' | translate" [link]="true" (onClick)="goToLogin()"></p-button>
    </div>
</app-login-layout>
