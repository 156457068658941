import {TranslateService} from "@ngx-translate/core";
import {PrimeNGConfig} from "primeng/api";

export function translateCalendar(translate: TranslateService, primengConfig: PrimeNGConfig): any {
  const calendarTranslationKeys = [
    'calendar.dayNames.monday',
    'calendar.dayNames.tuesday',
    'calendar.dayNames.wednesday',
    'calendar.dayNames.thursday',
    'calendar.dayNames.friday',
    'calendar.dayNames.saturday',
    'calendar.dayNames.sunday',
    'calendar.dayNamesShort.monday',
    'calendar.dayNamesShort.tuesday',
    'calendar.dayNamesShort.wednesday',
    'calendar.dayNamesShort.thursday',
    'calendar.dayNamesShort.friday',
    'calendar.dayNamesShort.saturday',
    'calendar.dayNamesShort.sunday',
    'calendar.dayNamesMin.monday',
    'calendar.dayNamesMin.tuesday',
    'calendar.dayNamesMin.wednesday',
    'calendar.dayNamesMin.thursday',
    'calendar.dayNamesMin.friday',
    'calendar.dayNamesMin.saturday',
    'calendar.dayNamesMin.sunday',
    'calendar.monthNames.january',
    'calendar.monthNames.february',
    'calendar.monthNames.march',
    'calendar.monthNames.april',
    'calendar.monthNames.may',
    'calendar.monthNames.june',
    'calendar.monthNames.july',
    'calendar.monthNames.august',
    'calendar.monthNames.september',
    'calendar.monthNames.october',
    'calendar.monthNames.november',
    'calendar.monthNames.december',
    'calendar.monthNamesShort.january',
    'calendar.monthNamesShort.february',
    'calendar.monthNamesShort.march',
    'calendar.monthNamesShort.april',
    'calendar.monthNamesShort.may',
    'calendar.monthNamesShort.june',
    'calendar.monthNamesShort.july',
    'calendar.monthNamesShort.august',
    'calendar.monthNamesShort.september',
    'calendar.monthNamesShort.october',
    'calendar.monthNamesShort.november',
    'calendar.monthNamesShort.december',
    'calendar.today',
    'calendar.clear',
    'calendar.weekHeader',
  ];

  translate.get(calendarTranslationKeys).subscribe((translations: Record<string, string>) => {
    primengConfig.setTranslation({
      dayNames: [
        translations['calendar.dayNames.sunday'],
        translations['calendar.dayNames.monday'],
        translations['calendar.dayNames.tuesday'],
        translations['calendar.dayNames.wednesday'],
        translations['calendar.dayNames.thursday'],
        translations['calendar.dayNames.friday'],
        translations['calendar.dayNames.saturday'],
      ],
      dayNamesShort: [
        translations['calendar.dayNamesShort.sunday'],
        translations['calendar.dayNamesShort.monday'],
        translations['calendar.dayNamesShort.tuesday'],
        translations['calendar.dayNamesShort.wednesday'],
        translations['calendar.dayNamesShort.thursday'],
        translations['calendar.dayNamesShort.friday'],
        translations['calendar.dayNamesShort.saturday'],
      ],
      dayNamesMin: [
        translations['calendar.dayNamesMin.sunday'],
        translations['calendar.dayNamesMin.monday'],
        translations['calendar.dayNamesMin.tuesday'],
        translations['calendar.dayNamesMin.wednesday'],
        translations['calendar.dayNamesMin.thursday'],
        translations['calendar.dayNamesMin.friday'],
        translations['calendar.dayNamesMin.saturday'],
      ],
      monthNames: [
        translations['calendar.monthNames.january'],
        translations['calendar.monthNames.february'],
        translations['calendar.monthNames.march'],
        translations['calendar.monthNames.april'],
        translations['calendar.monthNames.may'],
        translations['calendar.monthNames.june'],
        translations['calendar.monthNames.july'],
        translations['calendar.monthNames.august'],
        translations['calendar.monthNames.september'],
        translations['calendar.monthNames.october'],
        translations['calendar.monthNames.november'],
        translations['calendar.monthNames.december'],
      ],
      monthNamesShort: [
        translations['calendar.monthNamesShort.january'],
        translations['calendar.monthNamesShort.february'],
        translations['calendar.monthNamesShort.march'],
        translations['calendar.monthNamesShort.april'],
        translations['calendar.monthNamesShort.may'],
        translations['calendar.monthNamesShort.june'],
        translations['calendar.monthNamesShort.july'],
        translations['calendar.monthNamesShort.august'],
        translations['calendar.monthNamesShort.september'],
        translations['calendar.monthNamesShort.october'],
        translations['calendar.monthNamesShort.november'],
        translations['calendar.monthNamesShort.december'],
      ],
      today: translations['calendar.today'],
      clear: translations['calendar.clear'],
      weekHeader: translations['calendar.weekHeader'],
    });
  });
}
