import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../../store/global/global.state';
import { take } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { selectToken } from '../../../../store/global/global.selectors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private store: Store<GlobalState>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.store.select(selectToken).pipe(
            take(1),
            switchMap((organizationId) => {
                if (!organizationId) {
                    return next.handle(req);
                }

                const modifiedRequest = req.clone({
                    headers: req.headers.set(
                        'Authorization',
                        'Bearer ' + organizationId,
                    ),
                });

                return next.handle(modifiedRequest);
            }),
        );
    }
}
