import { Component } from '@angular/core';
import { LogonPageStore } from './logon-page.store';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { Router } from '@angular/router';
import { whoami } from '../../../../store/global/global.actions';

const MS_TOKEN_KEY = '#id_token=';

@Component({
    selector: 'app-logon',
    template: '',
    providers: [LogonPageStore],
})
export class LogonPageComponent extends SubscriptionComponent {

    constructor(
        private readonly store: LogonPageStore,
        private readonly router: Router,
    ) {
        super();
        let url = window.location.href;
        if (url.includes(MS_TOKEN_KEY)) {
            this.msLogon(url);
        }

        this.subs.push(
            this.store.apiSuccess.msLogon.subscribe((it) => {
                if (!it) return;
                this.store.globalStore!.dispatch(whoami());
                this.router.navigateByUrl('/');
            }),
        );
    }

    private msLogon(url: string): void {
        let idToken = url.substring(url.indexOf(MS_TOKEN_KEY) + MS_TOKEN_KEY.length);
        const idxEnd = idToken.indexOf('&');
        if (idxEnd > 0) {
            idToken = idToken.substring(0, idxEnd);
        }
        
        this.store.api.msLogon({ idToken });
    }

}
