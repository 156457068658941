import { RuleObject } from '@it2go/types';
import { createReducer, on } from '@ngrx/store';
import * as actions from './global.actions';
import { createInitialGlobalState } from './global.state';

export const globalReducer = createReducer(
    createInitialGlobalState(),
    on(actions.setMobile, (state, { isMobile }) => ({ ...state, isMobile })),
    on(actions.setShowSidebar, (state, { isSideBarShown }) => ({
        ...state,
        isSideBarShown,
    })),
    on(actions.setShowSmallSidebar, (state, { isSmallSideBarShown }) => ({
        ...state,
        isSmallSideBarShown,
    })),
    on(actions.setLanguage, (state, { language }) => ({ ...state, language })),

    on(actions.globalSuccess, (state, newState) => {
        return {
            ...state,
            lastAction: newState.input,
        };
    }),

    on(actions.globalApiFailure, (state, newState) => {
        return {
            ...state,
            lastFailAction: newState.key,
        };
    }),

    on(actions.getRulesSuccess, actions.getRulesFailure, (state, { rules }) => {
        const parsedRules: Record<string, Set<string>> = {};
        (rules || []).forEach((it: RuleObject) => {
            if (!(it.resource in parsedRules)) {
                parsedRules[it.resource] = new Set<string>();
            }
            parsedRules[it.resource].add(it.action);
        });

        return {
            ...state,
            rules: parsedRules,
        };
    }),

    on(
        actions.setOrganization,
        actions.setOrganizations,
        actions.setCurrentOrganizationId,
        actions.setServices,
        actions.setCurrentActiveIntegrations,
        (state, newState) => ({ ...state, ...newState }),
    ),

    on(actions.whoamiSuccess, actions.whoamiFailure, (state, { whoami }) => ({
        ...state,
        currentUser: whoami || null,
    })),

    on(actions.watchVisitHistoryReceived, actions.updateVisitHistoryDataSuccess, (state, { visitHistory }) => {
            if (!visitHistory.url) return { ...state };
            let from = 0;
            if (visitHistory.url == state.visitHistory[0]?.url) {
                from = 1;
            }

            let newHistory = [visitHistory, ...(state.visitHistory.slice(from))];
            if (from === 0) {
                newHistory = newHistory.slice(0, -1);
            }

            return {
                ...state,
                visitHistory: newHistory,
            };
        },
    ),

    on(actions.getVisitHistorySuccess, (state, { visitHistory }) => ({
            ...state,
            visitHistory,
        }),
    ),
);
