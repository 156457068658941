import { ChangeDetectorRef, Component } from '@angular/core';
import { ShareFilePageStore } from './share-file-page.store';
import { ActivatedRoute } from '@angular/router';
import { FileExternalDownloadObject } from '@it2go/types';
import { HttpClient } from '@angular/common/http';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-share-file-page',
    templateUrl: './share-file-page.component.html',
    styleUrl: './share-file-page.component.scss',
    providers: [ShareFilePageStore],
})
export class ShareFilePageComponent extends SubscriptionComponent {
    name = '';
    nameEmpty = false;
    code = '';
    state: 'init' | 'loading' | 'loaded' = 'init';

    constructor(
        protected readonly store: ShareFilePageStore,
        private readonly route: ActivatedRoute,
        private readonly http: HttpClient,
        private readonly translate: TranslateService,
        private ref: ChangeDetectorRef,
    ) {
        super();
        this.subs.push(
            this.route.queryParams.subscribe(params => {
                this.code = params.code;
            }),
            this.store.apiFailure.downloadExternal.subscribe(() => {
                alert(this.translate.instant('shareFile.error'));
            }),
        );
    }

    protected async getFile(): Promise<void> {
        if (!this.name) {
            this.nameEmpty = true;
            return;
        }
        this.state = 'loading';
        this.store.api.downloadExternal({ code: this.code, user: this.name });

        const linkObject = (await this.store.get(
            this.store.apiSuccess.downloadExternal,
        )) as unknown as FileExternalDownloadObject;

        this.downloadFile(linkObject.url, linkObject.name);
        this.state = 'loaded';
        // beacause template does not react to state change
        this.ref.markForCheck();
    }

    private downloadFile(url: string, name: string): void {
        this.http.get(url, { responseType: 'blob' }).subscribe({
            next: (response: Blob) => {
                const blob = new Blob([response], { type: response.type });
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.setAttribute('download', name);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },
            error: (error: any) => {
                console.error('Error downloading file: ', error);
            },
        });
    }
}
