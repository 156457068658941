import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {
    constructor(private http: HttpClient) {
    }

    public login(username: string, password: string): Observable<{ accessToken: string }> {
        return this.http.post<{ accessToken: string }>('/api/auth/login', {
            username,
            password,
        });
    }
}
