<app-login-layout>
    <h2 class="text-white">{{ 'common.login.2fa' | translate }}</h2>
    <p-divider></p-divider>
    <input
        name="code"
        id="code"
        [(ngModel)]="code"
        pInputText
        class="inputType text-white"
        [placeholder]="'common.login.2faCode' | translate"
        (ngModelChange)="login()"
    />
    <ng-container *ngIf="err$ | async as err">
        <div class="text-white" *ngIf="err">{{ 'common.login.2faWrongCode' | translate }}</div>
    </ng-container>
</app-login-layout>
