import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { GqlService } from '../../content/service/gql.service';
import {
    AuthenticatedUserObject,
    NotificationGridObject,
    NotificationObject,
    OkObject,
} from '@it2go/types';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from '../../content/service/types/filter.types';
import {
    notificationFrag,
    notificationGridItemFrag,
} from './types/notification.types';

@Injectable()
export class NotificationsService extends GqlService {
    getNotificationTypes() {
        return this.query<string[]>(
            `
                query {
                    notificationType {
                        getAll
                    }
                }
            `,
        );
    }

    getNotifications(
        input: GridInput | null = null,
    ): Observable<NotificationGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    notification {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${notificationGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteNotification(id: string): Observable<unknown> {
        return this.apollo.mutate({
            mutation: gql`
        mutation {
          notification {
            delete(input: { id: "${id}" }) {
              ok
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
        });
    }

    updateNotification(notif: any): Observable<OkObject> {
        return this.apollo
            .mutate<{
                notifications: { update: { ok: boolean } };
            }>({
                mutation: gql`
        mutation {
          notification {
            read(input: { id: "${notif.id}", read: ${notif.read} }) {
              ok
            }
          }
        }
      `,
                fetchPolicy: 'network-only',
            })
            .pipe(
                map((result) => {
                    if (result.data!.notifications.update.ok) {
                        return notif;
                    }

                    throw new Error('Notification update failed');
                }),
            );
    }

    getSubscription(user: AuthenticatedUserObject): Observable<NotificationObject> {
        const roleId = user.roleId?.toString() || 'null';
        const groupIds = `[${(user.groups || []).join(',')}]`;

        return this.apollo
            .subscribe<{ notification: NotificationObject }>({
                query: gql`
                    subscription {
                        notifications(userId: "${user.id}", roleId: ${roleId}, groupIds: ${groupIds}) {
                            ${notificationFrag}
                        }
                    }
                `,
            })
            .pipe(map((result) => result.data!.notification));
    }
}
