import { createReducer } from '@ngrx/store';
import * as actions from './notifications.actions';
import { createInitialNotificationsState } from './notifications.state';
import { ReducerFactory } from '../../../shared/store/common/reducer.factory';

// function notificationArrayToMap(
//     notificationArray: NotificationGridItemObject[],
// ): Record<string, Record<string, NotificationGridItemObject>> {
//     return notificationArray.reduce<
//         Record<string, Record<string, NotificationGridItemObject>>
//     >((result, notif) => {
//         result[notif.type] = result[notif.type] ?? {};
//         result[notif.type][notif.id] = notif;
//
//         return result;
//     }, {});
// }

const factory = new ReducerFactory(actions);

export const notificationsReducer = createReducer(
    createInitialNotificationsState(),

    ...factory.create(
        'getNotificationTypes',
        'getNotifications',
    ),

    // on(actions.readNotificationSuccess, (state, { notification }) => ({
    //     ...state,
    //     notifications: {
    //         ...state.notifications,
    //         [notification.notification_type]: {
    //             ...state.notifications[notification.notification_type],
    //             [notification.id]: notification,
    //         },
    //     },
    // })),
    //
    // on(actions.deleteNotification, (state, { notification }) => {
    //     const newState = {
    //         ...state,
    //         notifications: {
    //             ...state.notifications,
    //             [notification.type]: {
    //                 ...state.notifications[notification.notification_type],
    //             },
    //         },
    //     };
    //     delete newState.notifications[notification.notification_type][
    //         notification.id
    //         ];
    //     return newState;
    // }),

    // on(actions.receiveNotificationSuccess, (state, { notification }) => ({
    //     ...state,
    //     notifications: {
    //         ...state.notifications,
    //         [notification.type]: {
    //             ...state.notifications[notification.notification_type],
    //             [notification.id]: notification,
    //         },
    //     },
    // })),

    // on(actions.getNotificationsSuccess, (state, { notifications }) => ({
    //     ...state,
    //     notifications: notificationArrayToMap(notifications),
    // })),

);
